<template>
  <b-card title="Gallery">
    <b-tabs>
     <b-tab active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Video</span>
        </template>
<AddVideo></AddVideo>
      </b-tab>
      <b-tab @click="$router.push('/add-gallery-images')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Images</span>
        </template>
        <router-view></router-view>
<!-- <AddGallery></AddGallery> -->
      </b-tab>
      <b-tab @click="$router.push('/gallery-images')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> Images</span>
        </template>
        <router-view></router-view>
<!-- <AddGallery></AddGallery> -->
      </b-tab>
 
     
     
    </b-tabs>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'


import AddGallery from './addGallery.vue';
import AddVideo from './addVideo.vue';


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,
    AddGallery,
    AddVideo
},
  data() {
    return {
   
    }
  },
}
</script>
